import Vue from "vue";
import Router from "vue-router";

// Containers
const TheContainer = () => import("@/containers/TheContainer");
const TheMobile = () => import("@/containers/TheMobile");

// Views
import MobileRouter from "@/router/mobile";
import ReserveRouter from "@/router/reserve";
import QuoteRouter from "@/router/quote";
import ServiceRouter from "@/router/service";
import SystemRouter from "@/router/system";

const Dashboard = () => import("@/views/Dashboard");
// Views - Components
const Login = () => import("@/views/pages/Login");

Vue.use(Router);

const router = new Router({
  mode: "history",
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});

export default router;

function configRoutes() {
  return [
    {
      path: "/login",
      name: "登入",
      component: Login,
    },
    {
      path: "/",
      redirect: "/dashboard",
      name: "首頁",
      component: TheContainer,
      children: [
        {
          path: "dashboard",
          name: "Dashboard",
          component: Dashboard,
        },
        ...ReserveRouter,
        ...QuoteRouter,
        ...ServiceRouter,
        ...SystemRouter
      ],
      beforeEnter(to, from, next) {
        let token = localStorage.getItem("authorization");
        if (token) next();
        else next("/login");

      },
    },
    {
      path: "/mobile",
      redirect: "mobile/quote/description",
      name: "行動裝置",
      component: TheMobile,
      children: [...MobileRouter],
    },
  ];
}
