import axios from 'axios'
import router from '../router';

const instance = axios.create()
instance.defaults.timeout = 10000
instance.defaults.baseURL = "https://ecs.bshade.net/api/"
instance.defaults.headers.post['Content-Type'] = 'application/json'

instance.interceptors.request.use(
    config => {
        if (localStorage.getItem('authorization')) config.headers.Authorization = "Bearer " + localStorage.getItem('authorization');
        return config;
    },
    error => Promise.reject(error))

instance.interceptors.response.use(Response => Response, error => {
    const { status } = error.response;
    if (status == 401) {
        localStorage.removeItem('authorization');
        router.push('/login')
    }
    return Promise.reject(error);
})

export default instance