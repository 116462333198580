const ReserveDescription = () => import("@/views/reserve/description");
const ReserveRecord = () => import("@/views/reserve/record");
const ReserveSetting = () => import("@/views/reserve/setting");
export default [
  {
    path: "reserve",
    redirect: "/reserve/description",
    name: "預約系統",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "description",
        name: "預約說明",
        component: ReserveDescription,
      },
      {
        path: "record",
        name: "預約紀錄",
        component: ReserveRecord,
      },
      {
        path: "setting",
        name: "預約設定",
        component: ReserveSetting,
      }
    ],
  },
]