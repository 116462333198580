import "regenerator-runtime";
import "core-js/stable";
import Vue from "vue";
import Notifications from "vue-notification";
import App from "./App";
import router from "./router";
import CoreuiVue from "@coreui/vue";
import { iconsSet as icons } from "./assets/icons/icons.js";
import store from "./store";
import axios from "./commons/axios";
import VueAxios from "vue-axios";
import VueDayjs from "vue-dayjs-plugin";
import Loading from "vue-loading-overlay";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Model from "./commons/models";
import Function from "./commons/functions";
import vSelect from "vue-select";
import { BootstrapVue } from 'bootstrap-vue'
import vuetify from './plugins/vuetify'
Vue.config.performance = true;

Vue.use(BootstrapVue)
Vue.use(CoreuiVue);
Vue.use(Notifications);
Vue.use(VueAxios, axios);
Vue.use(VueDayjs);
Vue.use(Loading, {
  canCancel: false,
  loader: "dots",
  color: "#0c79c2",
  opacity: 0.7,
});

Vue.prototype.$log = console.log.bind(console);
Vue.prototype.$model = Model;
Vue.prototype.$function = Function;

library.add(fab, fas);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("v-select", vSelect);

new Vue({
  el: "#app",
  router,
  store,
  icons,
  template: "<App/>",
  vuetify,
  components: {
    App,
  }
});
