// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox, 
// the second argument is the SVG content stripped of SVG tags
export const logo = ['175 35',`
<g id="surface1">
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(60%,60%,60%);fill-opacity:1;" d="M 51.429688 27.96875 C 52.039062 28.511719 53.148438 28.78125 54.75 28.78125 C 56.480469 28.78125 57.96875 28.171875 59.199219 26.949219 C 59.351562 26.789062 59.660156 26.421875 60.128906 25.859375 L 63.769531 27.160156 C 62.878906 28.390625 62.230469 29.199219 61.839844 29.601562 C 59.878906 31.558594 57.519531 32.539062 54.761719 32.539062 C 52.011719 32.539062 49.648438 31.558594 47.691406 29.601562 C 45.730469 27.640625 44.75 25.28125 44.75 22.519531 C 44.75 19.761719 45.730469 17.390625 47.691406 15.441406 C 49.648438 13.488281 52 12.5 54.75 12.5 C 57.121094 12.5 59.191406 13.210938 60.96875 14.640625 C 61.261719 14.890625 61.550781 15.148438 61.828125 15.429688 C 61.949219 15.550781 62.28125 15.921875 62.828125 16.539062 L 61 18.390625 Z M 57.511719 16.71875 C 57.101562 16.398438 56.179688 16.25 54.75 16.25 C 53.03125 16.25 51.550781 16.859375 50.328125 18.089844 C 49.109375 19.320312 48.488281 20.789062 48.488281 22.53125 C 48.488281 23.949219 48.648438 24.871094 48.960938 25.289062 Z M 57.511719 16.71875 "/>
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 71.980469 3.941406 L 71.980469 32.058594 L 68.339844 32.058594 L 68.339844 3.941406 Z M 71.980469 3.941406 "/>
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 81.800781 3.941406 L 90.921875 19.710938 L 100.011719 3.941406 L 103.328125 3.941406 L 103.328125 32.058594 L 99.699219 32.058594 L 99.699219 11.738281 L 90.929688 26.929688 L 82.121094 11.738281 L 82.121094 32.058594 L 78.480469 32.058594 L 78.480469 3.941406 Z M 81.800781 3.941406 "/>
</g>
`]
