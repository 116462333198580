export default [
  {
    path: "quote",
    name: "MobileQuote",
    component: () => import("@/views/mobile/line/quote/index"),
  },
  {
    path: "reserve",
    name: "MobileReserve",
    component: () => import("@/views/mobile/line/reserve/index"),
  },
];
