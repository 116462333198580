const QuoteDescription = () => import("@/views/quote/description");
const QuoteContent = () => import("@/views/quote/content");

export default [
  {
    path: "quote",
    redirect: "/quote/description",
    name: "報價系統",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "description",
        name: "報價說明",
        component: QuoteDescription,
      }
    ],
  },
]