const ServiceSetting = () => import("@/views/service/service");
const ServiceCountySetting = () => import("@/views/service/county");
const ServiceStatusSetting = () => import("@/views/service/status");

export default [
  {
    path: "service",
    redirect: "/service/service",
    name: "預約系統",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "service",
        name: "服務設定",
        component: ServiceSetting,
      },
      {
        path: "county",
        name: "區域設定",
        component: ServiceCountySetting,
      },
      {
        path: "status",
        name: "項目設定",
        component: ServiceStatusSetting,
      }
    ],
  },
]