const Password = () => import("@/views/system/password");

export default [
  {
    path: "system",
    redirect: "system/password",
    name: "系統相關",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "password",
        name: "修改密碼",
        component: Password,
      }
    ],
  },
]